.bd-bottom
{
    border-bottom: 1px solid $color-gray-300;
}
.social-bottom 
{
    padding-top: 15px;
    a {
        text-decoration: none;
    }
    .icon-socials 
    {
        display: inline-block;
        margin-right: 5px;
        height: 27px;
        width: 27px;
        transition-duration: 0.2s;
        background-size: 27px 27px !important;
        &:hover {
            transform: translateY(-3px);
            transition-duration: 0.2s;
        }
        &.icon-facebook {
            background: url(../imgs/template/icons/facebook-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/facebook.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
        &.icon-twitter {
            background: url(../imgs/template/icons/twitter-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/twitter.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
        &.icon-instagram {
            background: url(../imgs/template/icons/instagram-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/instagram.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
        &.icon-linkedin {
            background: url(../imgs/template/icons/linkedin-gray.svg) no-repeat 0px 0px;
            transition-duration: 0.2s;
            &:hover {
                background: url(../imgs/template/icons/linkedin.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
            }
        }
    }
}