// New Agon
.btn {
    font-family: $font-text;
    padding: 22px 32px;
    font-size: $font-lg;
    line-height: 22px;
    transition: 0.2s;
    letter-spacing: 0.45px;
    border-radius: 50px;
    &.btn-square
    {
        border-radius: 4px;
        background-color: $color-gray-900;
        color: $color-white;
        font-size: $font-sm;
        line-height: 14px;
        font-weight: bold;
        padding: 18px 20px;
        border: 2px solid transparent;
        &:hover
        {
            background-color: $color-white;
            color: $color-gray-900;
            border-color: $color-gray-900;
        }
    }
    &.btn-black-border
    {
        border: 1px solid $color-gray-900;
        padding: 12px 22px;
        border-radius: 8px;
        width: 100%;
        text-align: left;
        &:hover
        {
            background-color: $color-gray-900;
            background-image: url(../imgs/template/icons/arrow-small-right-w.svg);
            background-repeat: no-repeat;
            color: $color-white;
            transform: translateY(-2px);
            transition: 0.2s;
        }
    }
    &.btn-default {
        color: $color-gray-900;
        background-color: $background-gray-100;
        border-radius: 50px;
        font-size: $font-lg;
        line-height: 22px;
        font-family: $font-text;
        font-weight: bold;
        &:hover {
            background-color: $color-gray-900;
            background-image: url(../imgs/template/icons/arrow-small-right-w.svg);
            background-repeat: no-repeat;
            color: $color-white !important;
            transform: translateY(-2px);
            transition: 0.2s;
        }
    }
    
    &.btn-white
    {
        background-color: $background-white;
    }
    &.btn-bd-green-hover
    {
        color: $color-gray-600;
        border: 2px solid transparent;
        &:hover
        {
            color: $color-green-900 !important;
            background-color: $background-white !important;
            border: 2px solid $color-green-900;
        }
    }
    &.active
    {
        color: $color-green-900 !important;
        background-color: $background-white !important;
        border: 2px solid $color-green-900;
    }
    &.btn-select
    {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
    }
    &.btn-green-900
    {
        background-color: $color-green-900;
        font-family: $font-text;
        font-weight: bold;
        font-size: $font-lg;
        line-height: 22px;
        letter-spacing: 0%;
        color: $color-white;
        &:hover {
            background-color: $color-gray-900;
            background-image: url(../imgs/template/icons/arrow-small-right-w.svg);
            background-repeat: no-repeat;
            color: $color-white !important;
            transform: translateY(-2px);
            transition: 0.2s;
        }
    }
    &.btn-black
    {
        color: $color-white;
        background-color: $background-gray-900;
        border-radius: 50px;
        font-size: $font-lg;
        line-height: 22px;
        font-family: $font-heading;
        font-weight: bold;
        &.icon-arrow-right-white:hover {
            background-color: $color-gray-100;
            background-image: url(../imgs/template/icons/fi-sr-arrow-small-right.svg);
            background-repeat: no-repeat;
            color: $color-gray-900;
            transform: translateY(-2px);
            transition: 0.2s;
        }
        &:hover
        {
            background-color: $color-gray-200;
            color: $color-gray-900;
        }
    }
    &.btn-md
    {
        padding: 16px 20px;
    }
    &.shape-square
    {
        border-radius: 4px;
    }
    &.btn-link {
    font-family: $font-text;
    font-style: normal;
    font-size: $font-lg;
    line-height: 18px;
    color: $color-gray-900;
    text-decoration: none;
        &:hover {
            color: $color-gray-500;
        }
    }
    &.btn-pink
    {
        background-color: $color-orange-900;
        color: $color-white;
        border-radius: 4px;
        &:hover {
            background-color: $color-gray-900;
        }
    }
    &.btn-link-inter
    {
        font-family: $font-text !important;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
    &.btn-round-icon
    {
        background-image: url(../imgs/template/icons/btn-send.svg);
        background-repeat: no-repeat;
        background-position: -30px -21px;
        display: inline-block;
        height: 57px;
        width: 79px;
        border-radius: 0px;
        padding: 0px;
    }
    &.btn-square-green
    {
        background-color: $color-green-900;
        padding: 1px 22px;
        color:$color-white;
        border-radius: 8px;
        height: 48px;
        width: 120px;
    }
    &.btn-green-full
    {
        background-color: $color-green-900;
        padding: 1px 22px;
        color:$color-white;
        border-radius: 4px;
        height: 48px;
        width: 100%;
    }
    &.btn-media
    {
        border-radius: 4px;
        padding: 1px 16px 3px 16px;
        min-height: 36px;
        line-height: 32px;
        border: 1px solid $border-color-2;
        img
        {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
    }
    &.btn-login-google
    {
        border-radius: 8px;
        background-color: $background-white;
        padding: 13px 25px;
        display: block;
    }
    &.btn-tag
    {
        background-color: $color-gray-200;
        color: $color-gray-900;
        font-family: $font-heading;
        font-size: $font-sm;
        line-height: 18px;
        font-weight: 400;
        border-radius: 8px;
        padding: 12px 20px;
        &:hover
        {
            background-color: $color-gray-900;
            color: $color-white;
        }
    }
    &.btn-newsletter
    {      
        position: absolute;
        top: 10px;
        right: 10px;
        border-radius: 8px;
        padding: 16px 56px 16px 27px;
        border: 0px;
        font-family: $font-text;
        font-weight: bold;
        font-size: $font-lg;
        line-height: 22px;
        color: $color-white;
        background-color: $color-green-900;
        background-image: url(../imgs/template/icons/arrow-small-right-w.svg);
        background-repeat: no-repeat;
        background-position: center right 25px;
        background-size: 15px;
        ::placeholder
        {
            color: #98A2B3;
            font-size: 16px;
            line-height: 28px;
        }
    }
    &.btn-cart
    {
        background-color: $color-green-100;
        color: $color-green-900;
        padding: 5px 25px 5px 15px;
        border-radius: 8px;
        background-image: url(../imgs/page/homepage5/icon-plus.svg);
        background-repeat: no-repeat;
        background-position: center right 10px;
        font-family: $font-heading;
        font-weight: bold;
        font-size: $font-md;
        line-height: 24px;
        &:hover
        {
        background-color: $color-green-500;
        }
    }
    &.btn-explorer
    {
        background-color: $color-green-100;
        color: $color-green-900;
        padding: 5px 32px 5px 15px;
        border-radius: 8px;
        background-image: url(../imgs/page/homepage8/icon-arrow-green.svg);
        background-repeat: no-repeat;
        background-position: center right 12px;
        font-family: $font-heading;
        font-weight: bold;
        font-size: $font-md;
        line-height: 24px;
        &:hover
        {
        background-color: $color-green-500;
        }
    }
    &.btn-border
    {
        border: 1px solid $color-gray-200;
        padding: 13px 20px;
        &:hover
        {
            border-color: $color-green-1000;
            color: $color-green-1000;
        }
    }
}
.icon-arrow-right
{
    background-image: url(../imgs/template/icons/fi-sr-arrow-small-right.svg);
    background-repeat: no-repeat;
    background-position: center right 25px;
    padding-right: 56px;
}
.icon-arrow-left
{
    background-image: url(../imgs/template/icons/arrow-small-left-white.svg);
    background-repeat: no-repeat;
    background-position: center left 25px;
    padding-left: 56px;
    &:hover
    {
        background-image: url(../imgs/template/icons/arrow-small-left.svg) !important;
    }
}
.icon-arrow-right-white
{
    background-image: url(../imgs/template/icons/arrow-small-right-w.svg);
    background-repeat: no-repeat;
    background-position: center right 25px;
    padding-right: 56px;
}
.icon-triangle
{
    background-image: url(../imgs/template/icons/icon-triangle.svg);
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 30px;
    font-family: $font-text !important;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}
.btn-play-video
{
    position: absolute;
    top: 50%;
    margin-top: -30px;
    left: -34px;
    width: 64px;
    height: 72px;
    background: url(../imgs/template/icons/play-button.svg) no-repeat;
}
// End New Agon


/*button switch*/
.switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
    vertical-align: middle;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background-gray-100;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 5px;
    bottom: 5px;
    background-color: $color-gray-900;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
input:checked + .slider {
    background-color: $background-white;
}
input:focus + .slider {
    box-shadow: 0 0 1px $color-gray-900;
}
input:checked + .slider:before {
    -webkit-transform: translateX(34px);
    -ms-transform: translateX(34px);
    transform: translateX(34px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
/*End button Switch On Off*/
span.btn {
    cursor: unset;
}