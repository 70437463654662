.mw-720
{
    max-width: 720px;
    margin: auto;
    display: table !important;
    table-layout: fixed;
}
.banner-home-3
{
    .banner-hero
    {
        &::before
        {
            display: none;
        }
        .block-banner
        {
            padding-right: 80px;
        }
    }
    position: relative;
    padding-bottom: 140px !important;
    &::before
    {
        content: "";
        height: 351px;
        width: 400px;
        position: absolute;
        bottom: 40px;;
        left: 40px;
        background-size: contain;
    }
    &::after
    {
        content: "";
        height: 351px;
        width: 400px;
        position: absolute;
        bottom: 40px;;
        right: 20px;
        background-size: contain;
    }
}
.list-job-2-col
{
    .card-job-description
    {
        font-size: $font-sm;
        line-height: 22px;
    }
}
.findjob-homepage-2
{
    &::before
    {
        right: 100px !important;
        bottom: -55px !important;
    }
}
.block-job-bg-homepage-2
{
    &::before
    {
        right: 0px !important;
    }
}
.none-bg-body
{
    background: $background-white;
}
// New Agon
.bg-green-900
{
    background-color: $color-green-900;
    color: $color-white;
}
.list-partners
{
    display: inline-block;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $color-gray-300;
    padding-bottom: 50px;
    li
    {
        display: inline-block;
        width: 18%;
        text-align: center;
        margin-bottom: 30px;
    }
}
.block-we-do-2
{
    padding-left: 80px;
}
.block-img-we-do
{
    position: relative;
    .inner-image
    {
        position: relative;
    }
    .block-chart
    {
        position: absolute;
        bottom: -100px;
        right: -60px;
        width: 270px;
        img
        {
            box-shadow: $box-shadow-3;
            border-radius: 16px;
        }
    }
}
.block-we-do
{
    .inner-image
    {
        position: relative;
        .block-image-bottom
        {
            position: absolute;
            bottom: -80px;
            right: -80px;
            width: 313px;
            img
            {
                box-shadow: $box-shadow-3;
                border-radius: 16px;
            }
        }
    }
}
.list-icons
{
    .item-icon
    {
        position: relative;
        padding: 0px 0px 40px 80px;
        margin-bottom: 40px;
        border-bottom: 2px solid $color-gray-200;
        &.none-bd
        {
            border-bottom: 0px;
            padding: 0px 0px 20px 80px;
            margin-bottom: 20px;
        }
        .icon-left
        {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 64px;
        }
    }
}
.box-square
{
    width: 100%;
    height: 455px;
    border-radius: 16px;
    padding: 55px 50px 0px 50px;
    text-align: center;
    .box-image-inner
    {
        border-radius: 16px;
        padding: 23px 19px;
        margin-top: 30px;
        &.bg-color-1
        {
            background-color: #EBECF4;
        }
        &.bg-color-2
        {
            background-color: #CBD1E5;
        }
    }
}
.box-gray-100
{
    background-color: $color-gray-100;
    padding: 80px;
}
.box-img-user
{
    position: relative;
    padding: 15px 0px 0px 100px;
    margin-top: 32px;
    min-height: 80px;
    .img-user
    {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 80px;
        img
        {
            border-radius: 4px;
        }
    }
}
.block-pricing-2
{

    .btn
    {
        &.btn-black
        {
            border: 1px solid transparent;
            padding: 12px 22px;
            border-radius: 6px;
            width: 100%;
            text-align: center;
            &:hover
            {
                border: 1px solid $color-gray-900;
                background-color: $background-white;
                color: $color-gray-900;
            }
        }
    }
    .box-pricing-item
    {
        &:hover, &.active
        {
            background-color: $color-green-900;
            *
            {
                color: $color-white;
            }
            .text-body-small
            {
                color: $color-gray-100 !important;
            }
            .list-package-feature
            {
                li
                {
                    background: url(../imgs/template/icons/check-circle-pink.svg) no-repeat left center;
                }
            }
            .btn
            {
                &.btn-black
                {
                    border: 1px solid $color-white;
                    background-color: transparent;
                    color: $color-white;
                    background-image: url(../imgs/template/icons/arrow-small-right-w.svg);
                    background-repeat: no-repeat;
                    background-position: center right 25px;
                }
            }
        }
    }
    
}
// End New Agon