.box-newsletter {
    border-radius: 18px;
    padding: 52px 74px;
    .box-form-newsletter {
        background: $background-white;
        max-width: 500px;
        padding: 11px 12px;
        border-radius: 55px;
        form {
            display: flex;
            align-items: center;
            .input-newsletter {
                width: 90%;
                padding: 15px;
                border: 0px;
                min-height: 50px;
                font-family: 'Noto Sans';
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: $color-gray-400;
            }
            .input-newsletter::placeholder
            {
                font-family: 'Noto Sans';
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                color: $color-gray-400;
            }
            .font-heading {
                font-family: $font-heading;
                font-weight: 500;
            }
        }
    }
    .btn-send {
        background-image: url(../imgs/template/icons/btn-send.svg);
        background-repeat: no-repeat;
        background-position: -30px -21px;
        display: inline-block;
        height: 57px;
        width: 72px;
        border-radius: 0px;
        padding: 0px;
    }
    .block-chart
    {
        position: absolute;
        bottom: 0px;
        left: 0px;
        max-width: 255px;
        height: 194px;
        z-index: 2;
        img
        {
            box-shadow: $box-shadow-3;
            border-radius: 16px;
        }
    }
    .img-newsletter
    {
        box-shadow: $box-shadow-3;
        border-radius: 16px;
        max-width: 332px;
        position: absolute;
        bottom: -110px;
        right: 0px;
        display: inline-block;
        z-index: 1;
    }
}


/*page loading*/
.preloader {
    background-color: #f7f7f7;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    margin: 0 auto;
    img {
        max-width: 250px;
    }
}

.rate {
    height: 21px;
    padding: 0 10px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    &:not(:checked) {
        & > input {
            position: absolute;
            visibility: hidden;
        }
        & > label {
            float: right;
            width: 21px;
            height: 21px;
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
            font-size: 30px;
            color: #ccc;
            margin-right: 3px;
            &:before {
                content: "";
                width: 21px;
                height: 21px;
                display: block;
            }            
        }
    }
    &.small {
        transform: scale(0.7);
        -moz-transform: scale(0.7);
        -webkit-transform: scale(0.7);
        -o-transform: scale(0.7);
        -ms-transform: scale(0.7);
        padding: 0;
    }
}
.breacrumb-cover {
    background-color: $background-bg-5;
    padding: 20px 0;
}
.breadcrumbs {
        li {
            color: $color-gray-900;
            font-size: $font-md;
            display: inline-block;
            padding-left: 13px;
            position: relative;
            &::before {
                content: "/";
                position: absolute;
                top: 1px;
                left: 1px;
                color: $color-gray-900;
            }
            a {
                font-size: $font-md;
                color: $color-gray-900;
                text-decoration: none;
                &:hover {
                    color: $color-gray-900;
                }
            }
            &:last-child::before {
                color: $color-gray-900;
            }
            &:first-child::before {
                display: none;
            }
            &:first-child {
                padding-left: 0px;
            }
        }
    }
.text-center .nav {
    justify-content: center;
}
@-webkit-keyframes load7{
	0%,
	80%,
	100%{box-shadow:0 2.5em 0 -1.3em;}
	40%{box-shadow:0 2.5em 0 0;}
}
@keyframes load7{
	0%,
	80%,
	100%{box-shadow:0 2.5em 0 -1.3em;}
	40%{box-shadow:0 2.5em 0 0;}
}

.loader {
	border-radius: 50%;
	width: 2em;
	height: 2em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 1.8s infinite ease-in-out;
	color: $color-gray-900;
	font-size: 10px;
	margin: 80px auto;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	&:before {
		border-radius: 50%;
		width: 2em;
		height: 2em;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: load7 1.8s infinite ease-in-out;
		animation: load7 1.8s infinite ease-in-out;
		content: '';
		position: absolute;
		top: 0;
		left: -3.5em;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}
	&:after {
		border-radius: 50%;
		width: 2em;
		height: 2em;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		-webkit-animation: load7 1.8s infinite ease-in-out;
		animation: load7 1.8s infinite ease-in-out;
		content: '';
		position: absolute;
		top: 0;
		left: 3.5em;
	}
}
