.text-summary
{
    font-weight: bold;
    font-family: $font-heading;
    font-size: $font-lg;
    line-height: 32px;
    color: $color-gray-500;
}
.single-detail
{
    p
    {
        margin-bottom: 15px;
        font-size: $font-lg;
        font-family: $font-heading;
        color: $color-gray-500;
        line-height: 32px;
        font-weight: 400;
    }
    img
    {
        border-radius: 16px;
    }
    h1, h2, h3, h4, h5, h6
    {
        margin: 0px 0px 20px 0px;
        font-weight: 700;
        color: $color-gray-900;
    }
    h6 {
        font-size: 18px;
    }
}
.social-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
}

.box-comments
{
    display: inline-block;
    width: 100%;
    padding: 60px 0px 0px 0px;
    ul
    {
        li
        {
            display: inline-block;
            width: 100%;
            .item-comment
            {
                display: inline-block;
                width: 100%;
                padding: 0px 0px 0px 250px;
                position: relative;
                margin-bottom: 40px;
                .blog-img-user
                {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    margin-top: 0px;
                }
            }
            ul
            {
                padding-left: 56px;
            }
        }
    }
    .text-comment
    {
        border-radius: 0px 16px 16px 16px;
        background-color: $color-gray-100;
        color: $color-gray-900;
        padding: 20px;
    }
}
.bdr-10
{
    border-radius: 10px;
}
.box-quote
{
    border-radius: 20px;
    padding: 45px;
    background-color: $color-green-900;
    color: $color-white;
    margin: 50px 0px 50px 0px;
    .text-quote
    {
        background: url(../imgs/template/icons/left-quote.svg) no-repeat top center;
        padding: 75px 0px 0px 0px;
        font-size: 32px;
        line-height: 48px;
        font-family: $font-text;
        font-weight: 400;
        text-align: center;
    }
}
.box-user
{
    text-align: center;
    position: relative;
    margin: 35px 0px 20px 0px;
    .img-user
    {
        display: inline-block;
        width: 55px;
        margin-right: 10px;
        vertical-align: middle;
        img
        {
            border-radius: 50%;
        }
    }
}
.text-top
{
    vertical-align: top;
}
.share-social
{
    display: inline-block;
    height: 30px;
    width: 30px;
    background-position: center;
    background-repeat: no-repeat;
    &.share-fb
    {
        background-image: url(../imgs/page/blog/single/share-fb.svg);
    }
    &.share-tw
    {
        background-image: url(../imgs/page/blog/single/share-tw.svg);
    }
    &.share-pi
    {
        background-image: url(../imgs/page/blog/single/share-pi.svg);
    }
}