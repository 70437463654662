.nav-style-homepage-3
{
    li
    {
        button
        {
            &.active
            {
                color: $color-green-900;
                background: none;
            }
            &:hover
            {
                color: $color-green-900;
                background: none;
            }
        }
    }
    > li:last-child button
    {
        padding-right: 0px;
    }
}
.bg-body-homepage-3
{    
    &::before
    {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 901px;
        width: 901px;       
        z-index: -1;
    }
}
@media (min-width: 1560px)
{
    .banner-homepage-3
    {
        padding: 0px 0px 0px 145px;
    }
}
.banner-homepage-3
{
    &::before
    {
        display: none;
    }
    padding-top: 40px;
    .block-banner
    {
        padding-right: 250px;
    }
    .banner-imgs
    {
        &::before
        {
            content: "";
            z-index: 0;
            position: absolute;
            bottom: 0px;
            left: -70px;
            height: 54px;
            width: 56px;
        }
        .banner-sm1, .banner-sm2, .banner-sm3
        {
            position: absolute;
            top: 4%;
            left: -86px;
            z-index: 12;
        }
        .banner-sm1
        {
            img
            {
                width: 251px;
            }
        }
        .banner-sm2
        {
            top: 43%;
            left: -136px;
            img
            {
                width: 144px;
            }
        }
        .banner-sm3
        {
            top: 62%;
            left: -90px;
            img
            {
                width: 251px;
            }
        }
    }
}

// New Agon
.box-image
{
    position: relative;
    .btn-play-middle
    {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        width: 135px;
        height: 135px;
        background-size: 135px;
    }
}
.bg-orange-100
{
    background-color: $color-orange-100;
}

.accordion-item .accordion-button
{
    border-radius: 4px !important;
    padding: 20px 30px;
    background-color: $color-gray-200;
    border-width: 0;
    color: $color-gray-500;
}
.accordion-button:not(.collapsed)
{
    color: $color-white;
    background-color: $color-gray-900;
}
.accordion-button::after
{
    background-image: url(../imgs/template/icons/angle-down.svg);
}
.accordion-button:not(.collapsed)::after
{
    background-image: url(../imgs/template/icons/angle-up-white.svg);
    transform: rotate(0deg);
}
.accordion
{
    .accordion-item
    {
        margin-bottom: 25px;
        .accordion-header
        {
            line-height: 22px;
        }
        .accordion-collapse
        {
            border-width: 0;
        }
    }
}
.icon-home
{
    background: url(../imgs/template/icons/icon-home.svg) no-repeat 0px 0px;
    padding: 0px 0px 0px 30px;
}
.icon-wave
{
    position: relative;
    &::before
    {
        content: "";
        position: absolute;
        top: 30px;
        right: 50px;
        height: 165px;
        width: 165px;
        z-index: 1;
        background: url(../imgs/template/email.png) no-repeat;
    }
    .row
    {
        z-index: 2;
        position: relative;
    }
}
// End New Agon