.block-tags
{
    position: absolute;
    top: 17px;
    right: 150px;
    a
    {
        background-color: $color-gray-100;
        display: inline-block;
        padding: 10px 45px 10px 25px;
        border-radius: 10px;
        position: relative;
        .icon-close
        {
            position: absolute;
            top: 14px;
            right: 15px;
            height: 20px;
            width: 20px;
            background: url(../imgs/template/icons/icon-close.svg) no-repeat 0px 0px;
        }
    }
}
.list-icons-2
{
    display: inline-block;
    padding: 0px 0px 0px 40px;
    > li
    {
        width: 50%;
        float: left;
        padding-right: 40px;
        padding-left: 50px;
        background: url(../imgs/template/icons/icon-question.svg) no-repeat 0px 0px;
        margin-bottom: 20px;
        padding-top: 6px;
        ul
        {
            list-style: disc;
            color: $color-gray-500;
            padding-left: 20px;
        }
    }
}