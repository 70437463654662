.box-swiper {
    position: relative;
    .swiper-container {
        position: relative;
        .item-logo {
            border: 1px solid $color-gray-500;
            padding: 39px 0px 36px 0px;
            display: inline-block;
            width: 100%;
            text-align: center;
            border-radius: 12px;
            box-shadow: $box-shadow-2;
            img {
                max-width: 100%;
            }
            &:hover {
                border: 1px solid $color-gray-800;
                box-shadow: $box-shadow-3;
            }
        }
    }
    .swiper-button-next:after,
    .swiper-button-prev:after {
        content: "";
    }
    .swiper-button-next {
        background: url(../imgs/slider/swiper/next.svg) no-repeat 0px 0px;
        &:hover {
            background: url(../imgs/slider/swiper/next-active.svg) no-repeat 0px 0px;
        }
    }
    .swiper-button-prev {
        background: url(../imgs/slider/swiper/prev.svg) no-repeat 0px 0px;
        &:hover {
            background: url(../imgs/slider/swiper/prev-active.svg) no-repeat 0px 0px;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        width: 64px;
        height: 64px;
        margin-top: -55px;
    }
    .swiper-button-next {
        left: auto;
        right: 0px;
        top: -65px;
    }
    .swiper-button-prev {
        left: auto;
        right: 84px;
        top: -65px;
    }
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
        opacity: 1;
    }
}
.swiper-pagination {
    text-align: center;
    width: 100%;
}
.swiper-pagination-bullet {
    margin: 10px;
}
/*Style of the location of the div that wraps the custom pager*/
.swiper-pagination-custom {
    bottom: -5px;
}
.swiper-pagination-customs {
    background: url(../imgs/slider/swiper/dot.svg) no-repeat 0px 0px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
    margin-left: 4px;
    margin-right: 4px;
}
/*Customize the style representation when pager is activated*/
.swiper-pagination-customs-active,
.swiper-pagination-customs:hover {
    background: url(../imgs/slider/swiper/dot-active.svg) no-repeat 0px 0px;
    width: 12px;
    height: 12px;
    margin-bottom: -1px;
    margin-left: 3px;
    margin-right: 3px;
}
