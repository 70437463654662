.footer {
    // New Agon
    .width-20
    {
        width: 21%;
    }
    .width-16
    {
        width: 16%;
    }
    .footer-top
    {
        display: inline-block;
        width: 100%;
        padding: 0px 0px 40px 0px;
        margin-bottom: 40px;
        border-bottom: 1px solid $color-gray-200;
    }
    
    .menu-footer {
        display: inline-block;
        li {
            list-style: none;
            margin-bottom: 8px;
            a {
                color: $color-gray-500;
                font-family: $font-heading;
                font-size: $font-md;
                line-height: 28px;
                text-decoration: none;
                transition-duration: 0.2s;
                &:hover {
                    color: $color-green-900;
                    transition-duration: 0.2s;
                    padding-left: 3px;
                }
            }
        }
    }
    .footer-bottom {
        border-top: 1px solid $color-gray-200;
        padding: 30px 0px;
        color: $color-gray-500;
        font-size: $font-sm;
        a {
            text-decoration: none;
        }
        .icon-socials {
            display: inline-block;
            margin-left: 10px;
            height: 32px;
            width: 32px;
            transition-duration: 0.2s;
            &:hover {
                transform: translateY(-3px);
                transition-duration: 0.2s;
            }
            &.icon-facebook {
                background: url(../imgs/template/icons/facebook.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
                &:hover {
                     opacity: 0.8;
                    transition-duration: 0.2s;
                }
            }
            &.icon-twitter {
                background: url(../imgs/template/icons/twitter.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
                &:hover {
                     opacity: 0.8;
                    transition-duration: 0.2s;
                }
            }
            &.icon-instagram {
                background: url(../imgs/template/icons/instagram.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
                &:hover {
                     opacity: 0.8;
                    transition-duration: 0.2s;
                }
            }
            &.icon-linkedin {
                background: url(../imgs/template/icons/linkedin.svg) no-repeat 0px 0px;
                transition-duration: 0.2s;
                &:hover {
                    opacity: 0.8;
                    transition-duration: 0.2s;
                }
            }
        }
    }
    // End new Agon
}
#scrollUp {
	width: 42px;
    height: 42px;
    color: $color-white;
    right: 30px;
    bottom: 30px;
    border-radius: 30px;
    text-align: center;
    overflow: hidden;
    z-index: 999 !important;
    border: 0;
    background-color: #fff;
    transition-duration: 0.2s;
    background-color: $color-green-900;
    i {
        display: block;
        line-height: 46px !important;
        font-size: 20px;
        color: #fff;
    }
    &:hover {
        transform: translateY(-3px);
        transition-duration: 0.2s;
    }
}