.none-pattern
{
    &::before
    {
        display: none;
    }
}
.page-service-2
{
    background: url(../imgs/page/services/2/bg-banner.svg) no-repeat;
}